exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-dico-js": () => import("./../../../src/pages/dico.js" /* webpackChunkName: "component---src-pages-dico-js" */),
  "component---src-pages-ekonk-js": () => import("./../../../src/pages/ekonk.js" /* webpackChunkName: "component---src-pages-ekonk-js" */),
  "component---src-pages-ekonk-v-1-js": () => import("./../../../src/pages/ekonk-v1.js" /* webpackChunkName: "component---src-pages-ekonk-v-1-js" */),
  "component---src-pages-engineering-js": () => import("./../../../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shul-v-1-js": () => import("./../../../src/pages/shul-v1.js" /* webpackChunkName: "component---src-pages-shul-v-1-js" */)
}

